

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f9fa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-primary {
  color: #fff;
  background-color: #d3a000;
  border-color: #d3a000; /*set the color you want here*/
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open>.dropdown-toggle.btn-primary {
  color: #fff;
  background-color: #a47b01;
  border-color: #a47b01; /*set the color you want here*/
}

.meycis-bd-color{background-color: #dba2b3 !important}
.bg-meycs{ background-color: #d3a000; }
.btn-disabled{ background-color: #d3a000; }

.sidebar {
  background-color: #f8f9fa; /* Color de fondo del Sidebar */
}

.sidebar-link {
  padding: 10px 20px; /* Espaciado interno de los enlaces */
}

.sidebar-link:hover {
  background-color: #e9ecef; /* Efecto hover */
}

.btn:disabled{
  background: #d3a000;
}

#basic-navbar-nav > div > .nav-item > a{
  color: rgba(var(--bs-light-rgb),var(--bs-text-opacity))!important;
}